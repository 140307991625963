import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Features from "../components/Features";
import Testimonials from "../components/Testimonials";

export const ProjectsPageTemplate = (
    { image,
      title,
      project,
      testimonials
    }) => {

  return (
      <div className="content">
        <div
            className="photo-cover-container"
            style={{
              backgroundImage: `url(${
                  !!image.childImageSharp ? image.childImageSharp.fluid.src : image
              })`,
            }}
        >
          <h2
              className="has-text-weight-bold is-size-1"
              style={{
                color: 'white',
                padding: '1rem',
              }}
          >
            {title}
          </h2>
        </div>
        <section className="section section--gradient">
          <div className="container">
            <div className="section">
              <div className="columns">
                <div className="column is-10 is-offset-1">
                  <Features gridItems={project.blurbs} />
                  {/*
                  <Testimonials testimonials={testimonials} />
                  */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
  )
}

ProjectsPageTemplate.propTypes = {
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    title: PropTypes.string,
    project: PropTypes.shape({
        blurbs: PropTypes.array,
    }),
    testimonials: PropTypes.array,
}

const ProjectsPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
      <Layout>
        <ProjectsPageTemplate
            image={frontmatter.image}
            title={frontmatter.title}
            project={frontmatter.project}
            testimonials={frontmatter.testimonials}
        />
      </Layout>
  )
}

ProjectsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default ProjectsPage

export const projectsPageQuery = graphql`
  query ProjectsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        project {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
        }
        testimonials {
          author
          quote
        }
      }
    }
  }
`
